import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import ContactUs from '../components/ContactUs'

import routes from '../routes'

const useStyles = makeStyles((theme) => ({
  main: {
    paddingLeft: theme.gutters.mobile,
    paddingRight: theme.gutters.mobile,

    [theme.breakpoints.up('desktop')]: {
      paddingLeft: theme.gutters.desktop,
      paddingRight: theme.gutters.desktop,
    },
  },
  header: {
    color: '#2FA43B',
  },
  bold: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
    color: '#4377bb',
  },
  hero: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    height: 350,
    top: -48, // Account for the header bar height
    paddingTop: 48,
    marginTop: -20,

    [theme.breakpoints.up('tablet')]: {
      marginTop: -15,
    },

    [theme.breakpoints.up('desktop')]: {
      marginTop: 24,
      height: 450,
    },
  },
  titleContainer: {
    width: '100%',
    background:
      'linear-gradient(179.63deg, #192B3E 61.31%, rgba(0, 0, 0, 0.28) 206.5%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,

    [theme.breakpoints.up('tablet')]: {
      height: 150,
    },

    [theme.breakpoints.up('desktop')]: {
      height: 250,
    },
  },
  block: {
    display: 'block',
  },
}))

export default function PrivacyPolicy() {
  const classes = useStyles()
  return (
    <>
      <div className={classes.hero}>
        <div className={classes.titleContainer}>
          <Typography component='div' variant='h1' style={{ color: 'white' }}>
            Privacy Policy
          </Typography>
        </div>
      </div>
      <main className={classes.main}>
        <Typography variant='body1' component='div'>
          This Privacy Policy applies to personally identifying information
          accessed and processed by CycurID Technologies Inc. (“CycurID,”
          “imme&trade; ”,“we,” “us,” and “our”) in the course of our business as
          collected from our website (the “Site”), our web and mobile
          applications (the “Apps”), and our related online and offline
          offerings (collectively, the “Services”).
          <br />
          <br />
          We strive to be the leaders in the personal data and identity market.
          Your privacy is paramount to us, and as this Privacy Policy explains,
          we treat your personal information quite a bit differently than some
          of our competitors.
          <br />
          <br />
          Except for any binary face data required to create a Digital ID (see
          the paragraph entitled “Face Data” below), WE DO NOT STORE YOUR
          PERSONAL INFORMATION ON OUR SERVERS. Once you have set up your
          imme&trade; account (“Account”), in accordance with our{' '}
          <Link to={routes.TERMS}>Terms of Use</Link>,{' '}
          <span style={{ fontweight: 900 }}>
            ALL OF YOUR PERSONAL INFORMATION IS ENCRYPTED AND STORED ON YOUR
            MOBILE DEVICE AND OUR SERVERS ARE WIPED OF YOUR PERSONAL
            INFORMATION.
          </span>
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          DEFINITIONS
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          <span className={classes.bold}>“anonymized data”</span> or{' '}
          <span className={classes.bold}>“data anonymization” </span>
          is the process of either encrypting or removing personally
          identifiable information from data sets, so that the people whom the
          data describe remain anonymous.
          <br />
          <br />
          <span className={classes.bold}>“De-identified data” </span>
          is data which has certain personal identifying characteristics deleted
          or masked such as personal name or date of birth.
          <br />
          <br />
          <span className={classes.bold}>“personal information”</span> or{' '}
          <span className={classes.bold}>
            “personally identifying information”
          </span>{' '}
          means any information that is capable of identifying an individual,
          such as name, address, phone number, email address, birth date,
          images, payment information and/or other identifiers.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          PERSONAL INFORMATION WE REQUIRE TO REVIEW
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We review and validate the information that you voluntarily provide to
          us or authorize us to do so. When you use our Services, and set up an
          Account we need to access the following categories of personal
          information to verify and validate who you are and to determine if
          your personal identification has been compromised:
          <br />
          <br />
          <span className={classes.bold}>Contact Information. </span>
          We will access and validate your name, email address, postal address,
          phone number, username.
          <br />
          <br />
          <span className={classes.bold}>Selfie Images. </span>
          We ask you to submit selfie images to verify your identity and use it
          for onboarding.
          <br />
          <br />
          <span className={classes.bold}>Identity Information. </span>
          The App allows you to store various forms of encrypted identification
          or identification documents and information on your mobile device.
          This may include your government-issued identification documents
          (e.g., passport, driver’s license), Social Security number, and other
          similar documents and information. We will access personal information
          contained in these documents (e.g., birthdate, gender, address,
          government-issued identifiers, etc.) and compare to verify your
          identity and ensure your personal identity has not been compromised.
          <br />
          <br />
          <span className={classes.bold}>Biometric Information. </span>
          We use facial recognition technology to extract biometric information
          contained in facial images that you provide to the Services. For
          example, we may compare the face on your government-issued photo ID
          against a selfie that you provide to us. We use this biometric
          information for identity verification, fraud prevention and
          onboarding.
          <br />
          <br />
          <span className={classes.bold}>Binary Face Data. </span>
          More particularly, the imme&trade; App uses your binary face data to
          create a Digital ID upon successful account creation and liveness
          verification. The binary face data is stored on private and secure
          servers managed by CycurID and cannot be used in any manner to
          identify you. We do not share this face data with third parties. Users
          can deactivate their account and request for the data to be deleted.
          <br />
          <br />
          By accepting our{' '}
          <Link className={classes.link} to={routes.TERMS}>
            Terms of Use
          </Link>{' '}
          and Privacy Policy and providing us with your selfies and your
          identification documents, you consent to the extraction and processing
          of the biometric data contained therein as set forth in this Privacy
          Policy.
          <br />
          <br />
          <span className={classes.bold}>
            Financial Account Information.
          </span>{' '}
          Where applicable, we work with Financial Service Providers (FSP’s) to
          examine and verify information from the financial institution(s) you
          use. This information includes, but is not limited to, a unique token
          issued by those FSP’s that allows us to access information from your
          financial accounts, account information, information about your
          account balances (including current and available balances),
          information about account transactions, including amount, date, type,
          quantity, price, and a description of the transaction, and other
          information. We may use this information provided by a FSP to verify
          your financial information and to construct an income/purchasing power
          score.
          <br />
          <br />
          We do not store or share your any of your personal financial
          information in accordance with this Privacy Policy. Information
          accessed is for verification purposes only and is in accordance with
          the FSP’s Privacy Policy. All of the financial information provided by
          your financial institution for verification purposes is done so in
          accordance with their respective terms and conditions, legal
          disclaimers and privacy policies.
          <br />
          <br />
          <span className={classes.bold}>Communications with Us. </span>
          We may collect personal information from you such as email address,
          phone number, or mailing address when you request information about
          our Services, sign up for our newsletter, request customer or
          technical support, or otherwise communicate with us.
          <br />
          <br />
          <span className={classes.bold}>Customer Service and Support. </span>
          If you interact with our customer service and support, we may collect
          the information you provide to our representatives. In addition, we
          may record telephone calls between you and our representatives for
          training and quality assurance purposes.
          <br />
          <br />
          <span className={classes.bold}>Interactive Features. </span>
          We may offer interactive features such as commenting functionalities,
          forums, chat services, and social media pages. We may collect the
          information you submit or make available through these interactive
          features. Any information shared on the public sections of these
          channels will be considered “public” and may not be subject to the
          privacy protections referenced herein.
          <br />
          <br />
          <span className={classes.bold}>
            Conferences, Trade Shows, and other Events.
          </span>{' '}
          We may attend conferences, trade shows, and other events where we
          collect personal information from individuals who interact with or
          express an interest in us and/or the Services. If you provide us with
          any information at one of these events, we will use it for the
          purposes for which it was collected.
          <br />
          <br />
          <span className={classes.bold}>Surveys. </span>
          We may contact you to participate in surveys. If you decide to
          participate, you may be asked to provide certain information which may
          include personal information.
          <br />
          <br />
          <span className={classes.bold}>Social Media Site. </span>
          We may access/collect personal information from your social media
          accounts such as email address, date of birth, job, pictures in order
          to further validate your identity or the look for adverse media
          mentions when using our Services and Apps.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          <span style={{ textDecoration: 'underline' }}>
            NON-PERSONALLY IDENTIYING INFORMATION
          </span>{' '}
          WE COLLECT THROUGH USE OF THE SERVICES
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We may collect certain information automatically when you use the
          Services, engage with our social media pages, communicate with us via
          email, or otherwise interact with us through alternative digital
          services. This information may include your Internet protocol (IP)
          address, user settings, IMEI, MAC address, Technologies (as defined
          below) including cookie identifiers, mobile advertising and other
          unique identifiers, mobile carrier, details about your browser,
          operating system or device, access times, location information
          (including inferred location based off your IP address), Internet
          service provider, pages that you visit before, during and after using
          the Services, information about the links you click, and other
          information about how you use the Services. Information we collect may
          be associated with your account and the devices you use.
          <br />
          <br />
          <span className={classes.bold}>
            Cookies, Web Beacons, and Personalized Advertising.
          </span>{' '}
          We may use cookies, pixel tags, local storage, and other technologies
          (“Technologies”) to automatically collect information through the
          Services. Technologies are essentially small data files placed on our
          Site that allow us to record certain pieces of information whenever
          you visit or interact with the Services.
          <br />
          <br />
          <span className={classes.bold}>Cookies. </span>
          Cookies are small text files placed in visitors’ device browsers to
          store their preferences. Most browsers allow you to block and delete
          cookies. However, if you do that, the Services may not work properly.
          <br />
          <br />
          <span className={classes.bold}>Pixel Tags/Web Beacons. </span>
          A pixel tag (also known as a web beacon) is a piece of code embedded
          on the Services that collects information about users’ engagement. The
          use of a pixel allows us to record, for example, that a user has
          visited a particular web page. We may also include web beacons in
          emails to understand whether messages have been opened, acted on, or
          forwarded.
          <br />
          <br />
          <span className={classes.bold}>Analytics.</span> We may use Google
          Analytics and other service providers to collect information regarding
          behavior and demographics on our Services. For more information about
          Google Analytics, please visit{' '}
          <a
            href='http://www.google.com/policies/privacy/partners/'
            className={classes.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            www.google.com/policies/privacy/partners/
          </a>
          . You can opt out of Google’s collection and processing of data
          generated by your use of the Site by going to{' '}
          <a
            href='http://tools.google.com/dlpage/gaoptout'
            className={classes.link}
            target='_blank'
            rel='noopener noreferrer'
          >
            http://tools.google.com/dlpage/gaoptout
          </a>
          .
          <br />
          <br />
          Third parties may also collect information via tracking technologies
          when you interact with our Services. Third parties will generally
          include (though not necessarily be limited to) search engines,
          providers of measurement and analytics services, social media
          networks, and advertising companies. The use of these Technologies and
          any information about you that third parties might collect using those
          Technologies is governed by each third party’s specific privacy
          policy, not this one. Third party advertising partners may collect
          information about you when you interact with their content,
          advertising, or services. This Privacy Policy only addresses the use
          and disclosure of information that you provide or allow access to
          during the sign up process either on the App or the Site. The
          Services, the Site or the App may contain links to other
          websites/applications and other websites/applications may reference or
          link to our Services, Site or App. These third party services are not
          controlled by us and we are not at any time or in any way responsible
          for the information practices of any third parties. We encourage you
          to read the privacy policies of each website and application with
          which you interact. We do not endorse, screen or approve, and are not
          responsible for the privacy practices or content of such other
          websites or applications. Visiting these other websites or
          applications is at your own risk.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          INFORMATION FROM OTHER SOURCES
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We may collect information about you from additional online and
          offline sources including commercially available third-party sources,
          such as consumer reporting agencies, various social media sites for
          the purposes of verifying eligibility and securely offering our
          Services to you. We may combine this information with the personal and
          other information we have collected about you under this Privacy
          Policy.
          <br />
          <br />
          We do not knowingly collect your personal information from a third
          party unless you consent.
          <br />
          <br />
          Notwithstanding the above, we reserve the right to collect your
          personal information from any third party without your consent if such
          collection is otherwise exempted, required or permitted by applicable
          laws to do so. For example, we may collect your personal information
          without your consent from the following third parties:
          <br />
          <br />
          &bull; Your authorized representative(s) (for example, your authorized
          agents, legal representatives or family members that you have
          authorized to speak to us on your behalf);
          <br />
          <br />
          &bull; Organization(s) to whom you have previously consented to
          collect your personal information; or
          <br />
          <br />
          &bull; Public sources in which your personal information is publicly
          available.
          <br />
          <br />
          If we collect your personal information from a third party to whom you
          have previously consented as regards collecting your personal
          information, we will only process that information for the specific
          purpose for which it was provided to us in accordance with this
          Privacy Policy and the policy under which that information was
          originally collected.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          WHY WE COLLECT PERSONAL INFORMATION AND HOW IT MAY BE USED
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We may use personal information (prior it to being erased from our
          servers) submitted by our customers and users as necessary to provide
          the Services, including updating, enhancing, securing and maintaining
          the Services and to carry out our contractual obligations to you and
          our customers.
          <br />
          <br />
          More particularly, we process personal information for a variety of
          business purposes, including:
          <br />
          <br />
          &bull; To provide the Services, products, or information requested.
          <br />
          <br />
          &bull; Fulfill our contract with you.
          <br />
          <br />
          &bull; Verify your identity
          <br />
          <br />
          &bull; Manage your information and IDs.
          <br />
          <br />
          &bull; Process your payment card and/or other financial information to
          facilitate your use of the services.
          <br />
          <br />
          &bull; Respond to questions, comments and other requests.
          <br />
          <br />
          &bull; Answer requests for customer or technical support.
          <br />
          <br />
          &bull; Pursue legitimate interests, such as direct marketing, research
          and development (including marketing research), network and
          information security, and fraud prevention.
          <br />
          <br />
          &bull; Measure interest and engagement in the Services.
          <br />
          <br />
          &bull; Develop new products and services.
          <br />
          <br />
          &bull; Improve our products and Services.
          <br />
          <br />
          &bull; Ensure internal quality control and safety.
          <br />
          <br />
          &bull; Carry out audits.
          <br />
          <br />
          &bull; Communicate with you about activities on the Services and
          changes to our agreements.
          <br />
          <br />
          &bull; Prevent and prosecute potentially prohibited or illegal
          activities.
          <br />
          <br />
          &bull; Enforce our agreements and comply with our legal obligations.
          <br />
          <br />
          <span className={classes.bold}>
            De-identified and Aggregated Information Use.
          </span>{' '}
          We may use personal information and other information about you to
          create de-identified and/or aggregated information, such as
          de-identified demographic information, de-identified location
          information, de-identified or aggregated trends, reports, or
          statistics, or other analyses we create. De-identified and aggregated
          information is not personal information, and we may use and disclose
          such information in a number of ways, including research, internal
          analysis, analytics, and any other legally permissible purposes. An
          example of De- identified and Aggregated Information would be:
          imme&trade; has “X” number of female users who reside in Toronto,
          Canada between the ages of 19 years to 29 years.
          <br />
          <br />
          <span className={classes.bold}>Technologies: </span>
          Our uses of such Technologies fall into the following general
          categories:
          <br />
          <br />
          <span className={classes.bold}>Operationally Necessary. </span>
          This includes Technologies that allow you access to our Services and
          that are required to identify irregular behavior, prevent fraudulent
          activity and improve security or that allow you to make use of our
          functions.
          <br />
          <br />
          <span className={classes.bold}>Performance Related. </span>
          We may use Technologies to assess the performance of our Services,
          including as part of our analytic practices to help us understand how
          our visitors use the Services.
          <br />
          <br />
          <span className={classes.bold}>Functionality Related. </span>
          We may use Technologies that allow us to offer you enhanced
          functionality when accessing or using our Services. This may include
          identifying you when you sign into our Services and keeping track of
          your specified preferences or past pages viewed.
          <br />
          <br />
          <span className={classes.bold}>Consent. </span>
          We may use personal information for other purposes that are clearly
          disclosed to you at the time you provide personal information or with
          your consent. We will process your personal information as set out in
          this Privacy Policy only with your knowledge and consent, except where
          exempted, required or permitted by applicable laws. The form of
          consent may vary depending on the circumstances and the type of
          information being requested. Your consent may be express with clear
          options to say “yes” or “no”, such as by being asked to check a box to
          indicate your consent, or implied, such as when you provide us with
          your address through a form or email seeking information and we use
          those means to respond to your request. Your consent can also be
          provided by your authorized representative. Taking into account the
          sensitivity of your personal information, purposes of collection, and
          your reasonable expectations, we will obtain the form of consent that
          is appropriate to the personal information being processed.
          <br />
          <br />
          By using our Sites, applications, Products and Services, or otherwise
          by choosing to provide us with your personal information (such as your
          selfies and identification documents) you acknowledge and consent to
          the accessing, extraction, and processing of your personal information
          in accordance with this Privacy Policy and as may be further
          identified when the personal information is collected. When we process
          your personal information for a new purpose, we will document that new
          purpose and ask for your consent again.
          <br />
          <br />
          If you do not consent to the processing of your personal information
          in accordance with this Privacy Policy, please do not access or
          continue to use any of the Sites, applications, Products and Services
          or otherwise provide any personal information to us.
          <br />
          <br />
          You may refuse to provide consent or may notify us at any time that
          you wish to withdraw or change your consent to the processing of your
          personal information without penalty, subject to legal or contractual
          restrictions and reasonable notice by (i) changing your privacy
          preferences on your devices, (ii) deleting your Account and ceasing
          use of our applications, products and services, or (iii) opting out of
          the use of your personal information such as unsubscribing to any
          newsletter or mailing list subscription that we provide through the
          Sites, applications, products and services. However, if you withdraw
          or change your consent, we may not be able to provide you with the
          Services.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          HOW WE DISCLOSE YOUR INFORMATION
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          Your personal information may be accessed by or disclosed only to our
          employees who have a need to know such information as a result of a
          request by regulatory or law enforcement authorities. Such employees
          may include staffs from our legal, compliance and/or IT departments.
          <br />
          <br />
          WE DO NOT SELL YOUR PERSONAL INFORMATION (INCLUDING PLATFORM ACTIVITY
          AS DEFINED BELOW) OR DISCLOSE IT TO ANY THIRD PARTY MARKETERS.
          However, information may be shared or disclosed to the parties in the
          following circumstances:
          <br />
          <br />
          <span className={classes.bold}>Business Partners. </span>
          We may provide de-identified personal information to business partners
          to provide you with a product or service you have requested or might
          be interested.
          <br />
          <br />
          <span className={classes.bold}>
            APIs and Software Development Kits.{' '}
          </span>
          We may use third party APIs and software development kits (“SDKs”) as
          part of the functionality of our Services. APIs and SDKs may allow
          third parties to provide content that is more relevant to you. For
          more information about our use of APIs and SDKs, please contact us as
          set forth below.
          <br />
          <br />
          <span className={classes.bold}>
            Disclosures to Protect Us or Others.{' '}
          </span>
          We may access, preserve, and disclose your personal information if we
          believe doing so is required or appropriate to: (i) comply with law
          enforcement or national security requests and legal process, such as a
          court order or subpoena; (ii) protect your, our or others’ rights,
          property, or safety; (iii) to collect amounts owed to us; (iv) when we
          believe disclosure is necessary or appropriate to prevent financial
          loss or in connection with an investigation or prosecution of
          suspected or actual illegal activity; or (v) if we, in good faith,
          believe that disclosure is otherwise necessary or advisable.
          <br />
          <br />
          <span className={classes.bold}>General. </span>
          You may have the right to object to or opt out of certain uses of your
          personal information. Where you have consented to the processing of
          your personal information, you may withdraw that consent at any time
          by contacting us as described below. Upon receiving direction to opt
          out we will delete all of your personal information from our servers.
          <br />
          <br />
          <span className={classes.bold}>Email & Text Communications. </span>
          We will not send you any marketing or related commercial electronic
          messages unless you have provided us with explicit consent to send you
          such emails, which may include commercial electronic messages
          regarding products or services you have requested. You will always
          have the option to use the unsubscribe link found at the bottom of any
          email you receive from us.
          <br />
          <br />
          <span className={classes.bold}>Mobile Devices. </span>
          We may send you push notifications through our App. You may at any
          time opt out from receiving these types of communications by changing
          the settings on your mobile device. With your permission, we may also
          collect precise location information if you use the App. You may
          opt-out of this collection by changing the settings on your mobile
          device.
          <br />
          <br />
          <span className={classes.bold}>Affiliates. </span>
          We may transfer personal information to our affiliates from time to
          time as permitted by this Privacy Policy.
          <br />
          <br />
          <span className={classes.bold}>Business Transactions. </span>
          We may be involved, from time to time, in transactions to sell all or
          part of our business or assets or merge with other businesses. Certain
          information may be relevant to such transactions, in which case we may
          disclose this information to other parties involved in the
          transaction. In such cases, the information that is shared is limited
          to what is necessary to accomplish the transaction, and we take
          appropriate steps to protect the information from improper use or
          disclosure. Upon completion of the transaction, your information may
          be transferred to the purchaser, to be used in the same manner that it
          was used by us.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          INDIVIDUAL RIGHTS IN PERSONAL INFORMATION
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          For purposes of the California Consumer Privacy Act, we do not “sell”
          your personal information.
          <br />
          <br />
          In accordance with applicable law, you may have the right to: (i)
          request confirmation of whether we are processing your personal
          information; (ii) obtain access to or a copy of your personal
          information; (iii) receive an electronic copy of personal information
          that you have provided to us, or ask us to send that information to
          another company (the “right of data portability”); (v) seek correction
          or amendment of inaccurate, untrue, incomplete, or improperly
          processed personal information; and (vi) request erasure of personal
          information which we hold about you , subject to certain exceptions
          prescribed by law and technical restrictions inherent in the
          blockchain. If you would like to exercise any of these rights, please
          contact
          <a className={classes.link} href='mailto:support@cycurid.com'>
            {' '}
            support@cycurid.com
          </a>
          .
          <br />
          <br />
          We will process such requests in accordance with applicable laws. To
          protect your privacy, we will take steps to verify your identity
          before fulfilling your request.
          <br />
          <br />
          If you are a California resident, you have the right not to receive
          discriminatory treatment by us for the exercise of your rights
          conferred by the California Consumer Privacy Act.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          DATA RETENTION
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We retain the personal information we receive as described in this
          Privacy Policy for as long as you use our Services or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, protect against fraud, enforce
          our agreements, and comply with applicable laws. We delete your
          biometric information once your account has been set up.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          SECURITY OF YOUR INFORMATION
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          We take the steps that we deem are commercially reasonable in an
          effort to ensure that your information, including data about your
          interests and payment behavior (the “Platform Activity”) is treated
          securely and in accordance with this Privacy Policy. The internet is
          inherently unsecure and we cannot guarantee that any information
          transacted or transmitted over the internet will ever be 100% secure
          nor that such information will be secure while in transit to us. To
          the fullest extent permitted by applicable law, we do not accept
          liability for unauthorized disclosure of any information transmitted
          over the internet.
          <br />
          <br />
          By using the Services or providing personal information to us, you
          agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues relating to your use of
          the Services. If we learn of a security system’s breach, we may
          attempt to notify you electronically by sending a notice through the
          Services or by sending an email to you.
          <br />
          <br />
          We do not share the Platform Activity with third party service
          providers that do not need such Platform Activity to provide their
          specific services to you.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          AUDIT, REGULATORY, LEGAL ENFORCEMENT
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          From time to time we may be requested by law enforcement, governmental
          agencies, or authorized third parties to verify our verification and
          validation procedures and to comply with generally accepted Know Your
          Customer and Anti-Money Laundering protocols, as well as other legal
          and industry compliance regulations. We may contact you and request
          you to open your encrypted personally identifying information for
          review by these parties. Failure to comply with these requests will
          result in the immediate cessation of Services provided by us and the
          immediate deletion of your Account. Failure to comply with such
          requests may be deemed to be a violation of our
          <Link className={classes.link} to={routes.TERMS}>
            {' '}
            Terms of Use.
          </Link>
          .
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          CHILDREN’S INFORMATION
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          The Services are not directed to minors under the age of 18 and anyone
          under the age of 18 may not use the Site without express consent from
          that individual’s parent or legal guardian. We reserve the right to
          not grant access to minors under the age of 18. We do not knowingly
          collect personally identifying information from anyone under the age
          of 18, and no part of the Site is designed to attract anyone under the
          age of 18. Because we do not intentionally collect any information
          from children under the age of 18, we also do not knowingly distribute
          such information to third parties nor knowingly distribute aggregated
          and anonymized information. If you have reason to believe that a child
          under 18 years of age has provided us with information, please contact
          us at{' '}
          <a className={classes.link} href='mailto:support@cycurid.com'>
            support@cycurid.com
          </a>{' '}
          and we will immediately take steps to delete such information, subject
          to and in compliance with applicable law.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          SUPERVISORY AUTHORITY
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          If you are located in the European Economic Area, you have the right
          to lodge a complaint with a supervisory authority if you believe our
          processing of your personal information violates applicable law.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          LIMITED LIABILITY
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          Please review our{' '}
          <Link className={classes.link} to={routes.TERMS}>
            Terms of Use.
          </Link>{' '}
          for limitation of Liability.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          CHANGES TO OUR PRIVACY POLICY
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          The personal identity market and related technology are rapidly
          changing. Therefore, from time to time, we will have to revise this
          Privacy Policy to reflect those changes. Any changes to this Privacy
          Policy will be posted by us to the Site and Apps, so you should review
          these pages periodically. If we make a material change to the Privacy
          Policy, you will be provided with appropriate notice. If we maintain
          your email address, we also may email you a copy of the revised
          Privacy Policy at your most recently provided email address. It is
          therefore important that you update your email address if it changes.
          <br />
          <br />
          This Privacy Policy was last updated on February 1, 2022.
        </Typography>
        <br />
        <br />
        <Typography variant='h6' component='div' className={classes.header}>
          CONTACT US
        </Typography>
        <br />
        <Typography variant='body1' component='div'>
          If you have any questions about our privacy practices or this Privacy
          Policy, please contact us by email at{' '}
          <a className={classes.link} href='mailto:support@cycurid.com'>
            support@cycurid.com
          </a>{' '}
          or by mail sent to:
        </Typography>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </main>
      <ContactUs />
    </>
  )
}
